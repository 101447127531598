import Image from 'next/image'
import Link from 'next/link'
import Tag from '../Atoms/Tag'
import { InformationCategory } from '@/types'

export default function ProjectCard({
  title,
  categories,
  tags,
  images,
  href,
  thumbnailEnter,
  thumbnailLeave,
  tagEnter,
  onLeave,
}: {
  title: string
  categories: any
  tags: { id?: string; name: string; slug: string; tag?: boolean }[]
  images: string
  href: string
  thumbnailEnter?: () => void
  thumbnailLeave?: () => void
  tagEnter: () => void
  onLeave: () => void
}) {
  return (
    <div
      className="relative group"
      onMouseEnter={tagEnter}
      onMouseLeave={onLeave}
    >
      <Link
        href={`${href}`}
        className="absolute left-0 top-0 w-full h-full block z-[1]"
      />
      <Link
        href={`${href}`}
        onMouseEnter={thumbnailEnter}
        onMouseLeave={thumbnailLeave}
        className="relative overflow-hidden rounded-[3px] block z-[2] transition duration-500 group-hover:shadow-[0_0_40px_-10px_rgba(92,111,131,0.4)]"
      >
        <Image
          alt=""
          src={images}
          width={700}
          height={300}
          className="w-full h-auto transition duration-500 group-hover:saturate-[120%] group-hover:brightness-95"
        />
      </Link>
      <p
        className={`font-bold h-[72px] md:h-[82px] text-[14px] md:text-[24px] leading-[1.7] mt-4 md:mt-6 line-clamp-3 md:line-clamp-2`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {categories && (
        <div className="flex items-center flex-wrap mt-2">
          {categories.map((item:InformationCategory, index:number) => (
            <>
              {item?.name && item?.slug && item?.name !== 'ピックアップ' &&
                item.name !== 'リクルートサイト' && (
                  <Tag
                    key={item.name + index}
                    href={item.slug}
                    type="button"
                    name={item.name}
                    mouseEnter={tagEnter}
                  />
                )}
            </>
          ))}
          {tags.map((item, index) => (
            <Tag
              key={item.name + index}
              href={''}
              type="text"
              name={'#' + item.name}
              mouseEnter={tagEnter}
            />
          ))}
        </div>
      )}
    </div>
  )
}
