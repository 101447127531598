export default function ReadJp({ text }: { text: string }) {
  return (
    <div className="relative mt-2">
      <h4
        className="text-[12px] md:text-[14px] leading-[1.8] tracking-wider"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}
