import Image from 'next/image'
import Link from 'next/link'

export default function SlideCardItem({
  image,
  title,
  description,
  href,
  thumbnailEnter,
  thumbnailLeave,
}: {
  image: string
  title: string
  description: string
  href: string
  thumbnailEnter?: () => void
  thumbnailLeave?: () => void
}) {
  return (
    <Link
      href={href}
      className="slide-inner relative block"
      onMouseEnter={thumbnailEnter}
      onMouseLeave={thumbnailLeave}
    >
      <p className="hidden md:inline md:px-6 md:py-8 text-white text-[20px] md:text-[24px] font-avenirNextBold leading-[1.3] absolute right-0 top-0 tracking-wider">
        {title}&apos;S
      </p>
      <p
        className="w-full p-5 md:p-8 text-white text-[14px] md:text-[20px] font-bold leading-[1.7] absolute left-0 bottom-0"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Image
        src={image}
        alt=""
        width={416}
        height={520}
        className="w-full h-full"
      />
    </Link>
  )
}
