import React from 'react'
import AnimatedText from './AnimatedText'

export default React.memo(function TitleEn({
  text,
  uppercase,
  fontNotoSan,
  disableAnimation,
}: {
  text: string
  uppercase?: boolean
  fontNotoSan?: boolean
  disableAnimation?: boolean
}) {
  return (
    <div className="relative pl-7">
      <span className="material-symbols-outlined text-blue_400 text-[25px] absolute left-0 top-2 md:top-1/2 md:-translate-y-1/2 md:-mt-[1px]">
        stat_0
      </span>
      <h3
        className={`${uppercase ? 'uppercase' : ''} ${fontNotoSan ? 'font-notosans_jp font-bold ' : 'font-avenirNextBold'} text-[24px] md:text-[32px] leading-[1.7] relative tracking-[1.28px]`}
      >
        {disableAnimation ? (
          text
        ) : (
          <AnimatedText txt_name={text} color="clip" />
        )}
      </h3>
    </div>
  )
})
