'use client'
import Cursor from '@/components/Atoms/Cursor'
import TitleEn from '@/components/Atoms/TitleEn'
import ReadJp from '@/components/Atoms/ReadJp'
import More from '@/components/Molecules/More'
import ProjectCard from '@/components/Molecules/ProjectCard'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { usePathname } from 'next/navigation'
import { Information } from '@/types/graphql'
import 'swiper/css'
import 'swiper/css/pagination'

export default function ProjectStory(props:{informations: Information[]}) {

  const informations = props.informations

  const pagination = {
    el: '.project-pagination',
    clickable: true,
  }
  const breakpoints = {
    769: {
      spaceBetween: 30,
    },
    320: {
      spaceBetween: 16,
    },
  }
  const path = usePathname()
  const { width } = useWindowSize()

  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()

  return (
    <div className="relative">
      <TitleEn text="Project story" uppercase />
      <div className="pr-6 md:pr-0">
        <ReadJp text="私たちの挑戦の背景には、職種・役職を越えて共通の目標を追いかけるチームの存在があります。<br>互いを尊重し、各々の力を発揮することで成功を収めたプロジェクトをご紹介します。" />
      </div>
      <div className="w-[calc(50%_+_94vw)] md:w-[calc(100vw_-_((100vw_-_1120px)_/_2))] relative !pt-8 md:!pt-10 -mr-[24px]">
        <div
          onMouseEnter={() => setMouseDisplay(true)}
          onMouseLeave={() => setMouseDisplay(false)}
        >
          <Swiper
            slidesPerView={'auto'}
            pagination={pagination}
            modules={[Pagination, Autoplay]}
            breakpoints={breakpoints}
            grabCursor={true}
            autoplay={false}
            className="project"
            touchEventsTarget="container"
            loop
          >
            {informations.map((item, index) => (
              <SwiperSlide
                className="!w-1/2 md:!w-[746px]"
                key={`project-${item.title}-${index}`}
              >
                {item.title && item.categories && item.informationTags && (
                  <ProjectCard
                  title={item.title}
                  categories={item.categories}
                  tags={item.informationTags}
                  images={
                    process.env.NEXT_PUBLIC_AWS_S3_URL +
                    '/' +
                    item.id +
                    '/original_' +
                    item.imageFileName
                  }
                  href={'/interview/' + item.slug}
                  thumbnailEnter={() => {
                    setMouseProperties({
                      text: ' VIEW',
                      color: '#ffffff',
                      background: '#6E95FF',
                    })
                    setMouseDisplay(true)
                  }}
                  thumbnailLeave={() => {
                    setMouseDisplay(false)
                  }}
                  onLeave={() => {
                    setMouseProperties({
                      text: 'DRAG',
                      color: '#ffffff',
                      background: '#242A32',
                    })
                    setMouseDisplay(true)
                  }}
                  tagEnter={() => {
                    setMouseDisplay(false)
                  }}
                />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {width > 768 && (
          <Cursor
            textMouse={mouseProperties.text}
            textColor={mouseProperties.color}
            background={mouseProperties.background}
            display={mouseDisplay}
            mixblendMode="mix-blend-screen"
          />
        )}
      </div>
      <div className="flex items-center justify-between md:block mt-8 md:mt-0 pr-6">
        {path !== '/interview' && (
          <div className="flex text-right md:text-left md:absolute right-0 top-0 flex-1 z-[99]">
            <More href="/interview/category/recruit_interview_project" text="View more" type="text" />
          </div>
        )}
        <div className="text-right project-pagination dot-pagination md:mt-10"></div>
      </div>
    </div>
  )
}
