'use client'
import React, { useState } from 'react'
import AnimatedText from '@/components/Atoms/AnimatedText'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectCreative, Pagination } from 'swiper/modules'
import Container from './Container'
import More from './More'
import SlideCardItem from '../Atoms/SlideCardItem'
import { usePathname } from 'next/navigation'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'
import { useWindowSize } from '@/hooks/useWindowSize'
import LottieAnimation from '../Atoms/LottieAnimation'
import { useCursor } from '@/hooks/useCursor'
import Cursor from '../Atoms/Cursor'
import { Information } from '@/types/graphql'

export default function SliderCard(props: { informations: Information[] }) {
  const informations = props.informations
  const path = usePathname()
  const { width } = useWindowSize()
  const [text, setText] = useState<string>('')
  const [href, setHref] = useState<string>('')

  const filterData = (data: Information, order: string) => {
    const selectedMetas = data.informationMeta?.filter((i) => i.name === order)
    if (selectedMetas) {
      const targetMeta = data.informationInformationMeta?.filter(
        (i) => i.informationMetumId === Number(selectedMetas[0].id)
      )
      return targetMeta ? targetMeta[0].value : 'test'
    } else {
      return 'null'
    }
  }
  const nameArray: string[] = []
  const hrefArray: string[] = []
  {
    informations.map((item) => {
      hrefArray.push(`/interview/${item.slug}`)
      nameArray.push(`${filterData(item, 'recruit_interview_pickup_name')}'S`)
    })
  }
  const changeText = (txt: string) => {
    setText(txt)
  }
  const changeHref = (href: string) => {
    setHref(href)
  }

  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()

  return (
    <div className="relative z-20">
      <Container size="sm">
        <div className="relative">
          <div
            className={`absolute md:right-1 z-30 ${path === '/interview' ? '-right-20 -top-28 md:-top-16' : '-right-20 -top-[130px] md:-top-32'}`}
          >
            <div className="w-[220px] md:w-[293px] h-auto">
              <LottieAnimation src={'/lottie/Arrow.lottie'} />
            </div>
          </div>
          <div className="slider-cards w-[220px] xs:w-[270px] lg:w-[416px] mx-auto mb-5 md:mb-0 relative before:w-full before:h-full before:rounded-[6px] before:bg-[#242a32] before:bg-cover before:bg-center before:absolute before:-top-[29px] before:rotate-[4deg] after:w-full after:h-full after:rounded-[6px] after:bg-[url('/images/top/card_bg.webp')] after:bg-cover after:bg-center after:absolute after:-top-[26px] left-[6px] after:rotate-[8deg]">
            <Swiper
              loop={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                reverseDirection: false,
              }}
              speed={300}
              pagination={{ el: '.card-pagination', clickable: true }}
              centeredSlides={false}
              grabCursor={true}
              effect={'creative'}
              slidesPerView={1}
              navigation={true}
              spaceBetween={0}
              creativeEffect={{
                prev: {
                  translate: [0, 0, -100],
                  rotate: [0, 0, 0],
                  shadow: true,
                  opacity: 0,
                },
                next: {
                  translate: ['10%', '-10%', 100],
                  rotate: [0, 0, 10],
                  shadow: true,
                  opacity: 0,
                },
              }}
              modules={[EffectCreative, Autoplay, Pagination]}
              onSlideChange={(swiper) => {
                changeText(nameArray[swiper.realIndex])
                changeHref(hrefArray[swiper.realIndex])
              }}
              className="creativeSwiper"
            >
              {informations.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="shadow-[0_4px_23px_0_rgba(92,111,131,.3)] rounded-[6px] overflow-hidden"
                >
                  <SlideCardItem
                    image={filterData(item, 'recruit_pickup_thumb')}
                    title={filterData(item, 'recruit_interview_pickup_name')}
                    description={filterData(
                      item,
                      'recruit_interview_pickup_title'
                    )}
                    href={`${href}`}
                    thumbnailEnter={() => {
                      setMouseProperties({
                        text: 'DRAG',
                        color: '#ffffff',
                        background: '#242A32',
                      })
                      setMouseDisplay(true)
                    }}
                    thumbnailLeave={() => setMouseDisplay(false)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {width > 768 && (
              <Cursor
                textMouse={mouseProperties.text}
                textColor={mouseProperties.color}
                background={mouseProperties.background}
                display={mouseDisplay}
                mixblendMode="mix-blend-hard-light"
              />
            )}
          </div>
          <div className="w-full flex justify-between items-end lg:block">
            <div className="md:absolute left-4 top-1/2 md:-translate-y-1/2">
              <div className="inline-block font-avenirNextBold text-[32px] md:text-[64px] leading-none text-blue_400 uppercase tracking-wider">
                <AnimatedText txt_name="IGNITE" color="blueClip" />
                <div className="md:hidden text-darkgrey_900">
                  <p>
                    <AnimatedText txt_name={text} color="clip" />
                  </p>
                  <AnimatedText txt_name="HEART" color="clip" />
                </div>
              </div>
              <div className="card-pagination dot-pagination"></div>
            </div>
            <div className="md:absolute right-4 top-1/2 md:-translate-y-1/2 md:mt-7">
              <p className="hidden md:block font-avenirNextBold text-[64px] leading-none text-darkgrey_900 uppercase tracking-wider">
                <AnimatedText txt_name="HEART" color="clip" />
              </p>
              <div className="mt-3 text-right pb-4 md:pb-0">
                <More
                  href={`${href}`}
                  text={`${width > 321 ? 'View more' : 'More'}`}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
