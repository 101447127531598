export default function Container({
  size,
  children,
}: {
  size: 'xl' | 'sm'
  children?: React.ReactNode
}) {
  return (
    <div
      className={`${
        size == 'xl' ? 'max-w-[1440px]' : 'max-w-[1168px]'
      } mx-auto px-6`}
    >
      {children}
    </div>
  )
}
